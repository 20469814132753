@media only screen and (min-device-width : 300px) and (max-device-width : 768px) {
    .mapGoogle iframe{
        width:100%;
        height:300px;
        margin:30px 0;
    }
    .footerCopyYear::before {
        display: none;
    }
    .footerCopyYear::after {
        display: none;
    }
    .tab_box .tab_btn {
        font-size: 14px;
    }
    .logoSloganLong{
        width: 100%;
        max-width: 100%;
    }
    .headerHeadTop nav ul {
        justify-content: center;
    }
    .headerHeadLink {
        margin: 0 10px 0 0;
    }
    .headerHeadLink a {
        font-size: 14px;
    }
    .line {
        position: absolute;
        top: 62px;
        left: 0;
        width: 114px;
        height: 5px;
        background-color: #003c7d;
        border-radius: 10px;
        transition: all .3s ease-in-out;
    }
    .headerLang {
        margin-left: 0;
    }
    .slider {
        height: 100%;
        border-bottom: 0;
    }
    .homeBestCategories {
        display: flex;
        display: -webkit-flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        min-height: 300px;
        position: relative;
        z-index: 98;        
    }
    .productContentHead {
        justify-content: center;
    }
    .productsOutput {
        justify-content: space-between;
        margin-left: 0;
    }
    .productItem h2 {
        font-size:14px;
    }
    .productItem {
        width:48%;
        margin: 0 0 10px 0;
        max-width: 100%;
    }
    .productGallery{
        margin-bottom: 20px;
    }
    .catalogItem {
        margin-right: 0;
    }
    .catalogContent {
        justify-content: space-between;
    }
    .catalogItem h3 {
        font-size: 14px;
    }
    .catalogContent {
        margin-right: 0;
    }
    .homeBestCatItem, .catalogItem {
        width: 42%;
        padding: 10px;
        margin-bottom: 5px;
    }
    .homeBestCatItem h3 {
        min-height: 40px;
        font-size: 15px;
    }
    .homeBestBtn {
        font-size: 12px;
        background-size:contain;
        margin-top: 10px;
    }
    .homeBestCatItem img{
        max-width: 100px;
    }
    .homeBestAboutNumbers {
        display: block;
    }
    .homeBestNumbers{
        padding-top:30px;
    }
    .headerCatalogNav{
        display: none;
    }
    .homeH2 {
        font-size: 30px;
    }
    .homeBestNumberItem {
        width: 180px;
    }
    .homeBestNumberNum {
        font-size: 72px;
    }
    .special img{
        width:100%;
        height:auto;
    }
    .special .specialText {
        top: 15px;
        font-size: 26px;
    }
    .special:first-child{
        margin-bottom: 10px;
    }
    .solutionItem{
        max-width: 42%;
        margin-bottom: 10px;
    }
    .commonTitle{
        font-size: 25px;
    }
    .solutionItem img{
        width: 100%;
        height: auto;
    }
    .container {
        padding: 0 10px;
    }
    .subcategoryItem {
        width: 42%;
        font-size: 14px;
        max-width: 100%;
        padding: 10px;
        margin:0 0 10px 0;
    }
    .commonBreadcrumbs ul li a {
        font-size: 10px;
    }
    .categoryOutput {
        align-items:normal;
        justify-content: space-between;
    }
    .subcategoryItem img {
        width: 90%;
        border-radius: 10px;
    }
    .commonBreadcrumbs ul:last-child li {
        font-size: 10px;
    }
    .solutionItem p {
        font-size: 14px;
    }
    .solutionsWrap {
        padding: 35px 0;
    }
    .articlesWrap {
        padding: 35px 0;
    }
    .articlesItem {
        width: 100%;
        margin:0 0 10px 0;
    }
    #whatsAppFixed {
        display: none;
    }
    .slick-slide{
        text-align: center;
    }
    main {
        background-image: none;
    }
    .partnersWrap {
        padding: 35px 0;
    }
    .footerLogoWrap {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .footer {
        justify-content: center;
        padding: 20px 0 0 0;
    }
    .scrollTopBtn{
        display: none;
    }
    body {
        font-size: 14px;
    }
    .productContent {
        padding: 10px;
        max-width: 100%;
        overflow: hidden;
    }
    .descrProduct table {
        display: block;
        max-width: -moz-fit-content;
        max-width: fit-content;
        margin: 0 auto;
        overflow-x: auto;
        white-space: nowrap;
        margin:20px 0;
        background-color: #fff;
    }
    th, td {min-width: 100px; }
    .brandsContent {
        justify-content: space-between;
    }
    .brandItem {
        width: 43%;
        background-color: #fff;
        padding: 10px;
        margin: 0 0 10px 0;
    }
    .headerLogo p {
        display: none;
    }
    ::-webkit-scrollbar {
        width: 8px;
        height: 5px;
    }
    .footerCategoryWrap {
        width: 344px;
    }
    .licenseContent a {
        display: block;
        max-width: 47%;
        margin-bottom: 20px;
    }
    footer{
        padding-bottom: 45px;
    }
    /*Mobile tel and whatsapp*/
    
    #mobile_bar .mobile_bar_item {
        display: inline-block;
        vertical-align: middle;
        width: 33.33%;
        float: left;
        text-align: center;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        margin: 8px 0;
    }
    #mobile_bar .mobile_bar_wrapper a, #mobile_bar_wrapper .copyrights a {
        display: block;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #fff;
        text-align: center;
        text-decoration: none;
    }
    .icon, .mobile_bar_icons_inner {
        display: block;
    }
    #mobile_bar .mobile_bar_wrapper a, #mobile_bar_wrapper .copyrights a {
        display: block;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #fff;
        text-align: center;
        text-decoration: none;
        display: flex;
        justify-content: center;
        font-size: 14px;
        align-items: center;
    }
    #mobile_bar .mobile_bar_item:last-child {
        border-right: none;
    }
    #mobile_bar .mobile_bar_item:first-child {
        border-left: none;
    }
    #mobile_bar .mobile_bar_item {
        display: inline-block;
        vertical-align: middle;
        width: 33.33%;
        float: left;
        text-align: center;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        margin: 8px 0;
    }
    .mobile_bar_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    #mobile_bar {
        background-color: #000000;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 100000;
        opacity: .8;
        border-top: 3px solid #003d9f;
    }
    #mobile_bar {
        display: block;
    }
    
    .mobile_bar_icons_inner svg {
        height: 20px;
        fill: #fff;
        margin-right: 20px;
    }
    .headerSearch svg {
        height: 28px;
        margin-right: 5px;
    }
    .headerLogoMobile{
        width: 160px;
        height: auto;
        position: fixed;
        top:8px;
        left:110px;
        z-index: 101;
    }
    .headerMobile{
        display: flex;
        justify-content: space-between;
        height: 50px;
        background-color: #111224;
        width: 100%;
        position: fixed;
        top:0;
        left:0;
        right:0;
        z-index: 100;
        border-bottom: 3px solid #449bdd;
    }
    header {
        padding-top: 60px;
    }
    .headerCatalogBtnMobile{
        margin: 15px 0 0 15px;
    }
    .headerMobileCatalog{
        display: block;
    }

    .header__menu__mobile{
        background-color: #fff;
        position: absolute;
        left: -1000px;
        bottom: 0;
        top:50px;
        transition: left 1s;
        height: 100vh;
        width: 100vw;
    }    
    .header__menu__mobile.active{
        left:0;
        right:0;
        transition: left 1s;
    }
    .headerHeadBtn{
        display: none;
    }
    .headerHeadNav{
        display: none;
    }
    .headerNavSearchWrap {
        margin-top: 5px;
        padding: 0;
        border:0;
        background-color: transparent;
    }
    .headerHeadTop{
        padding: 0 0 10px 0;
        justify-content: center;
    }
    .headerHeadTopWrap {
        margin-bottom: 0;
        border:0;
    }
    .headerSearchResult {
        background-color: #fff;
        border: 1px solid #ddd;
        height: 200px;
        left: 0;
        overflow: hidden;
        overflow-y: auto;
        padding-left: 20px;
        position: absolute;
        top: 40px;
        width: 350px;
        z-index: 200;
    }
    .headerMobileDataBtn{
        color:#fff;
        font-size: 10px;
        padding: 10px 5px;
        margin:8px 10px 0 0;
        border: 1px solid #003d9f;
    }
    .header__menu__mobile > ul{
        padding:30px 20px;
        list-style: none;
    }
    .header__menu__mobile > ul > li > a{
        text-decoration: none;
        color:#000000;
        font-size: 18px;
        pointer-events: none;
    }
    .header__menu__mobile > ul > li > a:first-child{
        display: flex;
        align-items: center;
        line-height: 1;
        margin: 10px 0;
    }
    .header__menu__mobile > ul > li > a img{
        margin-right: 10px;
    }
    .header__menu__mobile > ul > li > ul{
        padding:0 10px 0 50px;
        list-style: none;
    }
    .header__menu__mobile > ul > li > ul a{
        color:#000;
        text-decoration: none;
        padding: 5px 0;
        display: block;
    }
    .dropdown__menu{
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease-out;
    }

    .dropdown__item:hover .dropdown__menu{
        max-height: 1000px;
        transition: max-height 1s ease-in;
    }
    .headerMobileDataContent{
        position: absolute;
        right:-500px;
        transition: right 1s, opacity 1s;
        background-color: #fff;
        color: #000;
        top:53px;
        z-index: 10;
        opacity: 0;
        width: 100vw;
    }
    .headerMobileDataContent.active{
        right:0;
        transition: right 1s, opacity 1s;
        z-index: 10;
        opacity: 1;
    }
    .headerMobileDataContent ul{
        padding:30px;
    }
    .headerMobileDataContent ul li{
        list-style: none;
    }
    .headerMobileDataContent ul li a{
        text-decoration: none;
        color:#000;
        font-size: 20px;
        display: block;
        padding: 5px;
    }
    .headerSearch button {
        position: absolute;
        z-index: 2;
        background-color: transparent;
        border: none;
        outline: none;
        right: 0;
        cursor: pointer;
        top: 4px;
        border-left: 1px solid #264b71;
        padding: 3px 4px 0 7px;
    }
    .headerSearch input {
        border-radius: 10px;
        padding: 8px 50px 8px 20px;
        border: 1px solid transparent;
        width: 80%;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        outline: none;
        transition: ease .5s;
    }
    .headerHeadMobileBtn{
        color:#ffffff;
        background-image: url(../images/headerHeadBtn.png);
        background-position: center center;
        background-repeat: no-repeat;
        padding: 15px 45px 15px 90px;
        text-transform: uppercase;
        transition: ease .5s;
        cursor: pointer;
        background-color: transparent;
        border:none;
        display: inline-block;
        margin-left: 40px;
        margin-bottom: 20px;
    }
}
